import { useCallback, useMemo, useState } from 'react';

import { FullStory } from 'V2/Analytics';
import { createEventDispatcher } from 'V2/Utilities/EventDispatcher';

type EventHandler = (data: Record<string, any>) => void;
type FullstoryCommand =
  | 'metadata-persisted:on-update-set-page-properties'
  | 'metadata-persisted:set-page-properties';

export interface FullstoryMetadata {
  [key: string]: any;
}

const FS_METADATA_KEY = 'fs-fullstory-metadata';
const eventDispatcher = createEventDispatcher<FullstoryCommand>();
const activeListeners = new Set<FullstoryCommand>();

export function useFullstory() {
  const [metadata, setMetadata] = useState<FullstoryMetadata>(() => {
    const stored = sessionStorage.getItem(FS_METADATA_KEY);
    return stored ? JSON.parse(stored) : {};
  });

  const handlers: Record<FullstoryCommand, EventHandler> = useMemo(
    () => ({
      'metadata-persisted:on-update-set-page-properties': (
        data: Partial<FullstoryMetadata>
      ) => {
        FullStory.setPageVars(data);
      },
      'metadata-persisted:set-page-properties': (
        data: Partial<FullstoryMetadata>
      ) => {
        FullStory.setPageVars(data);
      },
    }),
    []
  );

  const ensureListenerIsActive = useCallback((command: FullstoryCommand) => {
    if (activeListeners.has(command)) {
      return;
    }

    eventDispatcher.on(command, handlers[command]);
    activeListeners.add(command);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePersistedFullstoryMetadata = useCallback(
    (newData: Partial<FullstoryMetadata>) => {
      setMetadata((prev) => {
        const updated = { ...prev, ...newData };

        if (JSON.stringify(updated) !== JSON.stringify(prev)) {
          sessionStorage.setItem(FS_METADATA_KEY, JSON.stringify(updated));

          ensureListenerIsActive(
            'metadata-persisted:on-update-set-page-properties'
          );
          eventDispatcher.emit(
            'metadata-persisted:on-update-set-page-properties',
            updated
          );
        }

        return updated;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setPersistedFullstoryPageProperties = () => {
    ensureListenerIsActive('metadata-persisted:set-page-properties');
    eventDispatcher.emit('metadata-persisted:set-page-properties', metadata);
  };

  return {
    metadata,
    fsCommands: {
      setPersistedFullstoryPageProperties,
      updatePersistedFullstoryMetadata,
    },
  };
}
