type HandlerData = Record<string, any>;
type EventHandler = (data: HandlerData) => void;

class EventDispatcher<TEvent extends string> {
  private static instance: EventDispatcher<any>;
  private events: Record<string, EventHandler[]> = {};

  private constructor() {}

  static getInstance<T extends string>(): EventDispatcher<T> {
    if (!EventDispatcher.instance) {
      EventDispatcher.instance = new EventDispatcher<T>();
    }
    return EventDispatcher.instance;
  }

  on(event: TEvent, handler: EventHandler) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(handler);
  }

  off(event: TEvent, handler: EventHandler) {
    if (this.events[event]) {
      this.events[event] = this.events[event].filter((h) => h !== handler);
    }
  }

  emit(event: TEvent, data: HandlerData) {
    if (this.events[event]) {
      this.events[event].forEach((handler) => handler(data));
    }
  }
}

export const createEventDispatcher = <T extends string>() =>
  EventDispatcher.getInstance<T>();
