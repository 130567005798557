import { ReactSDKClient } from '@optimizely/react-sdk';
import { Nullable, PropertiesType } from 'V2/Types';
import { getAnalytics } from 'V2/Services/GetAnalytics';
import { Datadog, DataLayer, setAnalytics } from 'V2/Analytics';

type InitApplicationType = (
  optimizely: Nullable<Pick<ReactSDKClient, 'setUser'>>
) => Promise<PropertiesType>;

export const initApplication: InitApplicationType = (optimizely) =>
  new Promise((resolve, reject) => {
    const appId = sessionStorage.getItem('appId');
    const clickId = sessionStorage.getItem('clickId');

    Datadog.addUserContext({
      appId,
      clickId,
    });

    getAnalytics()
      .then((response) => {
        const {
          cc_uuid: userId,
          flow_name,
          campaign_name,
          partner_slug_url: slug,
          partner_name,
          insurance_application_id,
          attribution_type,
        } = response.data;

        optimizely &&
          optimizely.setUser({
            id: userId as string,
            attributes: response.data,
          });
        DataLayer.push({
          event: 'attribution_data',
          flow_name: flow_name,
          campaign_name: campaign_name || '',
          slug,
          partner_name,
          insurance_application_id,
          partner_channel:
            typeof attribution_type === 'string' && attribution_type
              ? attribution_type.split('_')[0]
              : '',
        });

        setAnalytics(response.data);
        resolve(response.data);
      })
      .catch(reject);
  });
